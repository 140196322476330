// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }

  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }

  .cell-wrap {
    white-space: break-spaces;
    overflow-wrap: anywhere;
  }
}

.highlighted-text {
  background-color: rgba($primary, 0.1);
}

.search-box {
  label {
    margin-bottom: 0;
  }

  .search-input {
    background-color: #F3F3F9;
    border: none;
  }
}

.action-cell {
  width: 100px;
}




