//
// Forms.scss
//




[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
 &::placeholder{
   text-align: left /*rtl: right*/;
 }
}

.form-check{
  position: relative;
  text-align: left /*rtl: right*/;

  .form-check-label{
    display: block;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
  }
}


// checkbox input right

.form-check-right{
    padding-left: 0;
    display: inline-block;
    padding-right: $form-check-padding-start;
    .form-check-input{
      float: right;
      margin-left: 0;
      margin-right: $form-check-padding-start * -1;
    }
  
    .form-check-label{
      display: block;
    }
}

  

// checkbox

.form-checkbox-outline{
  
  .form-check-input{
    border-width: 2px;
    background-color: $card-bg;

    &:active{
      filter: none;
    }

    &:checked{
      background-color: $card-bg !important;

      &[type=checkbox]{
        background-image: none;
        
      }

      &:after{
        position: absolute;
        content: '\F012C';
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px; /*rtl: -4px */
        font-size: 16px;
        color: $dark;  
      }
    }
  }
}

// radio

.form-radio-outline{
  .form-check-input{
    background-color: $card-bg;
    position: relative;

    &:active{
      filter: none;
    }

    &:checked{
      background-color: $card-bg !important;
  
      &[type=checkbox]{
        background-image: none;
        
      }
  
      &:after{
        position: absolute;
        content: '';
        top: 3px!important;
        left: 3px;
        right: 3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}



// checkbox color

@each $color,
$value in $theme-colors {
  .form-check-#{$color}{
    .form-check-input{
      &:checked{
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color}{
    .form-check-input{
      &:checked{
        border-color: $value;
        background-color: $value;
        &:after{
          background-color: $value;
        }
      }
    }
  }
}

.form-check-label{
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-switch-md{
  padding-left: 2.5rem;
  min-height: 24px;
  line-height: 24px;
  .form-check-input{
    width: 40px;
    height: 20px;
    left: -0.5rem;
    position: relative;
  }

  .form-check-label{
    vertical-align: middle;
  }
}


.form-switch-lg{
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;
  .form-check-input{
    width: 48px;
    height: 24px;
    left: -0.75rem;
    position: relative;
  }
}

.input-group-text{
  margin-bottom: 0px;
}

.custom-radio {
  display: flex;
  align-items: center;

  input[type="radio"] {
    position: absolute;
    top: 0;
    opacity: 0;

    + .radio-label {
      display: flex;
      align-items: center;


      &:before {
        content: '';
        background: $white;
        border-radius: 100%;
        border: 1px solid darken($primary, 25%);
        display: inline-block;
        width: 16px;
        height: 16px;
        position: relative;
        margin-right: 4px;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $primary;
          box-shadow: inset 0 0 0 4px $white;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $primary;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $white;
          border-color: darken($white, 25%);
          background: darken($white, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
.inline-form {
  .inline-input {
    display: flex;
    align-items: flex-start;
    position: relative;

    label {
      flex: 0 0 150px;
      padding: 0.5rem 0;
    }

    span.invalid-feedback {
      position: absolute;
      left: 150px;
      top: 2rem
    }

    .textarea.invalid-feedback {
      top: auto;
      bottom: 0
    }
  }

  .right-block {
    width: calc(100% - 150px);
    margin-left: auto;
  }
}

.image-input {
  display: flex;
  width: 100%;
  gap: 10px;

  &__image {
    width: 36px;
    height: 36px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 3px;
  }
}



