// 
// authentication.scss
//

.auth-header {
    background-color: $auth-header-bg;

    .header-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-top: 14px;
    }
}


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// auth 2

.auth-logo-light{
    .logo {
        background-color: $red;
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.auth-logo{
    .auth-logo-dark{
        display: $display-block;
    }
    .auth-logo-light{
        display: $display-none;
    }
}

.auth-body-bg{
    background-color: $card-bg;
}


// auth-pass-inputgroup

.auth-pass-inputgroup{
    input[type="input"] + .btn .mdi-eye-outline{
        &:before{
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg{
    background-color: rgba($primary, 0.25);
    display: flex;

    @media (min-width: 1200px){
        height: 100vh;
    }
    

    &::before{
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .bg-overlay{
        background: url("../../../images/bg-auth-overlay.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.auth-full-page-content{
    display: flex;

    @media (min-width: 1200px){
        min-height: 100vh;
    }
}

.auth-review-carousel{
    &.owl-theme {
        .owl-dots {
            .owl-dot{
                span{
                    background-color: rgba($primary, 0.25);
                }
                &.active, &:hover{
                    span{
                        background-color: $primary;
                    }
                }
            }
        }
    }
}

//result-page
.card-result {
    line-height: 29px;
    text-align: center;
    font-size: 13px;
    color: #74788D;
    padding-top: 144px;
    padding-bottom: 40px;
    width: 450px;
    box-shadow: none;

    &__nav {
        gap: 10px;
    }

    &.danger:before {
        content: "";
        background-image: url("../../../images/error-send.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30px 30px;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        position: absolute;
        top: 40px;
        left: 50%;
        background-color: rgba($danger, 0.1);
        transform: translateX(-50%);
    }

    &.success:before {
        content: "";
        background-image: url("../../../images/bx-mail-send.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 30px 30px;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        position: absolute;
        top: 40px;
        left: 50%;
        background-color: rgba($success, 0.1);
        transform: translateX(-50%);
    }

    h2 {
        font-weight: 500;
        font-size: 19.5px;
        color: #495057;
    }
}