.nav-tabs-event-teams {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.event-teams-table__message {
    max-width: 30vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}