.report-details {
  .file-list {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;

    &__item {
      width: calc(20% - 10px);
      aspect-ratio: 1 / 1;
      object-fit: cover;
      cursor: pointer;
      background-color: $black;

      &.video {
        position: relative;

        &:after {
          content: '';
          width: 62px;
          height: 62px;
          background-color: $white;
          opacity: 0.2;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background-image: url("../../../images/play-arrow.png");
          background-position: 23px center;
          background-size: 20px 20px;
          background-repeat: no-repeat;
          transition: opacity .5s;
        }

        &:hover:after{
          opacity: .5;
        }
      }
    }
  }

ul {
    list-style-type: none;
    padding-left: 0;
  }
}

.modal-file-list {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__close-button {
    color: rgba($white, .5);
    width: 36px;
    height: 36px;
    z-index: 10;
    border-radius: 10px;
    border: 0;

    position: absolute;
    top: 24px;
    right: 24px;

    &:hover {
      color: rgba($white, 1);
    }

    &:after {
      content: '\00d7';
      color: $black;
      text-align: center;
      font-size: 18px;
    }
  }

  .modal-content {
    overflow: hidden;
    border: 0;
    background-color: $black;
    width: auto;
    height: auto;

    .carousel-gallery{
      min-width: 300px;
      min-height: 300px;

      .carousel-inner {
        height: 90vh;
      }

      &__item {
        width: 100%;
        height: 100%;
        max-width: 100%;
        background: black;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.report-comment-details-container {
  background: #FFFFFF;
}

.reports-history-scroll {
  max-height: 258px;
  overflow-y: scroll;
}

.reports-posts-history-scroll {
  max-height: 474px;
  overflow-y: scroll;
}

.reports-history {
  background: #F8F8FB;

  &-item {

    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    width: auto;

    .title {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      min-width: 120px;
      display: block;
      height: 100%;
    }

    .value {
      overflow-wrap: anywhere;
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 16px;
    padding: 2px 0;
    background-color: #EFF2F7;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #A6B0CF;
  }
}

.disabled-link {
  pointer-events: none;
}