.custom-controls {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 24px;
  left: 24px;
  right: 24px;
  height: 36px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 0 13px;

  &__progress {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  &__button {
    background-color: transparent;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    border: 0;

    &.rewind {
      background-image: url("../../../images/player/rewind.svg");
    }
    &.play {
      background-image: url("../../../images/player/play.svg");
    }
    &.pause {
      background-image: url("../../../images/player/pause.svg");
    }
    &.fast-forward {
      background-image: url("../../../images/player/fast-forward.svg");
    }
    &.mute {
      background-image: url("../../../images/player/mute.svg");

      &.active {
        background-image: url("../../../images/player/unmute.svg");
      }
    }
  }
}