.nav-tabs-container {
  .nav-tabs {
    padding: 12px;

    &__link {
      display: block;
      color: $secondary;
      border-bottom: 1px solid $gray-400;
      width: 200px;
      text-align: center;
      padding: 7px 0;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;

      &_active {
        color: $primary !important;
        border-bottom: 1px solid $primary;
      }
    }
  }

  @media (max-width: 991.98px) {
    .nav-tabs {
      padding: 4px;
    }
  }
}


