.profile-dropdown {
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $primary;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
  }
}