// 
// _pagination.scss
// 

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        min-width: 32px;
        min-height: 32px;
        padding: 0;
        text-align: center;
        line-height: 32px;
        border: 1px solid #CED4DA;
    }
  }